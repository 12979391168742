
.chart_container {
    height: 180px;
    width: 180px;
    padding: 8px;
}

.label-container > span:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #4E5969;
}

.label-container > span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #4E5969;
}

.label-container > span:last-child {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #4E5969;
}

.chart-header > span:first-child {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: right;
    color: #333333;
}

.chart-header > span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: right;
    color: #333333;
}

.chart-header > span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
    color: #333333;
}