/* Set column widths for my ea table */
.my-ea-grid  table thead tr th:nth-of-type(3){
    width: 12%
}
.my-ea-grid table tbody tr td:nth-of-type(3){
    width: 12%
}

.my-ea-grid  table thead tr th:nth-of-type(4){
    width: 12%
}
.my-ea-grid table tbody tr td:nth-of-type(4){
    width: 12%
}

.my-ea-grid  table thead tr th:nth-of-type(5){
    width: 8%
}
.my-ea-grid table tbody tr td:nth-of-type(5){
    width: 8%
}

.my-ea-grid  table thead tr th:nth-of-type(6){
    width: 8%
}
.my-ea-grid table tbody tr td:nth-of-type(6){
    width: 8%
}

.my-ea-grid  table thead tr th:nth-of-type(7){
    width: 8%
}
.my-ea-grid table tbody tr td:nth-of-type(7){
    width: 8%
}

.my-ea-grid  table thead tr th:nth-of-type(8){
    width: 12%
}
.my-ea-grid table tbody tr td:nth-of-type(8){
    width: 12%
}

.ea-menu-popup {
    background: #FFFFFF;
    border: 0.063rem solid #AAAAAA;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.ea-menu-title {
    color: black;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: none;
}

.ea-menu-link {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
}

.ea-menu-link-indent {
    margin-left: 2.25rem;
}

.ea-menu-link:hover {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
    color: var(--keysight-blue);
}

.ksm-icon-button-active {
    color: var(--keysight-red);
}

.start-from-scratch-modal .k-dialog-titlebar {
    background-color: transparent;
}

.start-from-scratch-modal .k-i-x {
    color: #424242 !important;
}

.start-from-scratch-modal-text {
    margin-bottom: 1rem;
}

.ksm-hamburger-dropdown .k-disabled {
    color: var(--keysight-gray-6);
    opacity: 100;
}

.k-animation-container > .k-popup {
    box-shadow: 0.031rem 0.078rem 0.25rem 0 #aaaaaa;
}

.ea-hamburger-indent {
    padding-left: 2.25rem;
}