@media (max-width: 1365px) {
    html {
        font-size: 12px;
    }
}

@media (min-width: 1365px) {
    html {
        font-size: 14px;
    }
}

@media (min-width: 1550px) {
    html {
        font-size: 16px;
    }
}

body {
    margin: 0;
    min-width: 1280px;
}

:root {
    --keysight-red: #E90029;
    --keysight-dark-red: #AF1326;
    --keysight-pink: #FFC2C2;
    --keysight-blue: #426DA9;
    --keysight-purple: #9B26B6;
    --keysight-light-purple: #F5E9F8;
    --keysight-gray-6: #333333;
    --keysight-gray-5: #403E44;
    --keysight-gray-4: #524F56;
    --keysight-gray-3: #D7D7D7;
    --keysight-gray-2: #E3DFE8;
    --keysight-gray-1: #F2F2F2;
    --keysight-yellow: #FCB300;
    --keysight-light-yellow: #FEEDC2;
    --keysight-green: #239706;
    --keysight-light-green: #C9F9BD;
    --keysight-primary: var(--keysight-red);
    --keysight-secondary: var(--keysight-gray-6);
    --keysight-alternate: var(--keysight-blue);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.k-tabstrip > .k-content > .k-animation-container {
    width: 100%
}

.k-page-container {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
    margin-bottom: 5rem;
    flex: 1;
}

.k-form-container {
    margin: auto;
    max-width: 53.125rem;
}

a {
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.help-anchor {
    margin-bottom: 1rem;
}

.k-anchor {
    line-height: 1.625rem;
    color: var(--keysight-alternate);
    font-weight: 700;
    text-decoration: none;
}

.k-anchor:hover {
    border-bottom: 0.125rem solid var(--keysight-alternate);
}

hr {
    color: #AAAAAA;
}

.k-overlay {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: #2F4F4F;
    opacity: 0.6;
    overflow-x: hidden;
    transition: 0.5s;
}

.k-input-header {
    font-size: 1.125rem;
    color: #424242;
}

.k-blue-cell {
    color: var(--keysight-alternate);
    font-weight: bold !important;
}

.column-menu .k-button {
    border-radius: 0;
    font-size: 1rem;
}

.column-menu .k-button[type="reset"] {
    background-color: white;
    color: var(--keysight-primary);
    border-color: var(--keysight-primary);
}

.ksm-anchor-button {
    line-height: 1.625rem;
    color: var(--keysight-alternate);
    font-size: 1rem;
    font-weight: 700;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
}

.ksm-anchor-button:hover {
    text-decoration: underline;
    text-decoration-thickness: 0.125rem;
    text-underline-offset: 0.125rem;
}

.ksm-icon-button {
    line-height: 1.625rem;
    color: var(--keysight-alternate);
    font-size: 1rem;
    /*font-weight: 700;*/
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
}

.ksm-active-button {
    cursor: pointer;
    pointer-events: auto;
    color: var(--keysight-alternate);
}

.ksm-deactivate-button {
    cursor: none;
    pointer-events: none;
    color: #AAAAAA;
}

.ksm-default-header {
    background-color: #524F56 !important;
}

.ksm-deactivate-header {
    cursor: none;
    pointer-events: none;
    background-color: #524F56 !important;
}

.k-button-outline:disabled {
    color: #9F9EA1;
}

.k-h1,
.k-h2,
.k-h4 {
    color: var(--keysight-secondary);
}

.k-h3 {
    color: var(--keysight-purple);
}

.k-h3.text-black {
    color: var(--keysight-secondary);
}

.k-h5 {
    color: var(--keysight-pink);
}

.k-text-nowrap {
    white-space: nowrap !important;
}

.k-button-icon {
    font-size: 1.375rem;
}

.k-header > .k-checkbox {
    border-color: #AAAAAA;
    background-color: var(--keysight-gray-4);
}

.k-header-desc {
    color: #424242;
    font-size: 0.875rem;
    font-style: italic;
}

.k-grid td {
    padding: 0.188rem 0.75rem;
}

.k-grid td > .k-checkbox {
    vertical-align: middle;
}

.license-grid-assign-qty > .k-input-inner {
    text-align: center;
}

.ksm-popover {
    border-radius: 0.313rem;
}

.ksm-popover > .k-popover {
    box-shadow: none;
    background-color: transparent;
}

.ksm-popover > .k-popover > .k-popover-callout {
    border-width: 0.125rem 0.125rem 0 0;
}

.ksm-popover > .k-popover > .k-popover-body {
    padding: 0.938rem 0.938rem;
}

.ksm-popover-error {
    border: 0.125rem solid var(--keysight-primary);
    border-radius: 0.313rem;
    background-color: var(--keysight-pink);
}

.ksm-popover-error > .k-popover > .k-popover-callout {
    background-color: var(--keysight-pink);
    border-color: var(--keysight-primary);
}

.ksm-popover-success {
    border: 0.125rem solid var(--keysight-green);
    border-radius: 0.313rem;
    background-color: var(--keysight-light-green);
}

.ksm-popover-success > .k-popover > .k-popover-callout {
    background-color: var(--keysight-light-green);
    border-color: var(--keysight-green);
}

.ksm-popover-warning {
    border: 0.125rem solid var(--keysight-yellow);
    border-radius: 0.313rem;
    background-color: var(--keysight-light-yellow);
}

.ksm-popover-warning > .k-popover > .k-popover-callout {
    background-color: var(--keysight-light-yellow);
    border-color: var(--keysight-yellow);
}

.ksm-popover-alert-info {
    border: 0.125rem solid var(--keysight-purple);
    background-color: var(--keysight-light-purple);
}

.ksm-popover-alert-info > .k-popover > .k-popover-callout {
    background-color: var(--keysight-light-purple);
    border-color: var(--keysight-purple);
}

.ksm-panelbar-checkbox > .k-checkbox {
    border-color: white;
    background-color: var(--keysight-alternate);
}

.k-selected td {
    background-color: var(--keysight-light-purple);
}

.k-pager-numbers .k-selected {
    border: 2px solid #426DA9;
}

.k-state-disabled .k-checkbox {
    border-color: #707070;
}

.k-state-disabled .k-checkbox {
    border-color: #707070;
}

.ksm-panelbar-default > .k-panelbar-header > .k-link {
    padding: 0.563rem 0.563rem;
}

.ksm-panelbar-no-arrow > .k-panelbar-header > .k-link > .k-icon {
    display: none;
}

.ksm-dialog > .k-window {
    border-color: #AAAAAA;
}

.ksm-dialog > .k-dialog {
    background: #FFFFFF;
    border-radius: 0.313rem;
}

.ksm-dialog > .k-dialog > .k-dialog-titlebar {
    background-color: white;
    border-radius: 0.313rem 0.313rem 0 0;
}

.ksm-dialog > .k-dialog > .k-dialog-titlebar > .k-dialog-title {
    color: var(--keysight-secondary);
    font-size: 1.5rem;
}

.ksm-dialog > .k-dialog > .k-dialog-titlebar > .k-dialog-actions > button > span {
    color: var(--keysight-alternate) !important;
}

.ksm-dialog > .k-dialog > .k-dialog-content {
    padding: 0.938rem 1.375rem 1.375rem 1.375rem;
}

.ksm-dialog > .k-dialog > .k-dialog-buttongroup {
    justify-content: center;
    padding: 1.313rem;
    gap: 0.938rem;
}

.ksm-upload > .k-dropzone > .k-button {
    border: none;
    background: none;
}

.ksm-upload > .k-dropzone > .k-dropzone-hint {
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-align: right;
    color: var(--keysight-alternate);
    margin-right: 1.25rem;
}

.ksm-upload > .k-dropzone > .k-upload-status {
    margin-right: 1.25rem
}

.ksm-dropdownlist > button > span {
    color: var(--keysight-alternate);
}

.host-file-upload {
    background-color: #fafafa;
}

.host-file-upload .k-button-md {
    padding: 0;
    border: 0;
    border-radius: 0;
}

.host-file-upload .k-dropzone-hint {
    color: var(--keysight-alternate) !important;
    font-weight: bold !important;
    font-size: 1.125rem;
}

.alert-close-button {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
}

.alert-close-button:hover {
    background: transparent;
}

.alert-close-button:focus {
    box-shadow: none;
}

tbody > tr.k-master-row.disabled > td > button {
    color: grey !important;
}

tbody > tr.k-master-row.k-alt.disabled > td .k-icon {
    color: grey !important;
}

.k-detail-cell {
    padding: 0px 16px 0px 0px !important;
}

.k-detail-row, .k-detail-row.k-alt {
    background-color: var(--keysight-gray-3) !important;
}

.k-upload-status > button:first-child {
    display: none;
}

.k-input, .k-input-inner {
    border-color: var(--grid-border);
}

.k-grid-pager .k-picker-md .k-input-inner {
    padding: 10%;
}

.shipment-button {
    display: flex;
    width: 150px;
    font-size: 14px;
}

.shipment-form {
    height: 95vh;
    margin-top: 2.5vh;
    position: fixed;
}

.capital-letters {
    text-transform: lowercase;
}

.capital-letters:first-letter {
    text-transform: uppercase;
}

.k-pager.k-widget.k-grid-pager {
    padding: 8px 8px;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: default;
    flex: 0 0 auto;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ksm-hamburger .k-button-flat:focus::after, .k-button-flat.k-focus::after {
    opacity: 0 !important;
}

.ksm-hamburger-dropdown li.k-item {
    font-size: 1rem;
}

.ksm-hamburger-dropdown li.k-item:focus {
    box-shadow: none !important;
}

.ksm-hamburger-dropdown .k-link:hover {
    color: var(--keysight-blue) !important;
    background-color: inherit !important;
}

.ksm-hamburger-dropdown .k-menu-link-text:hover {
    text-decoration-line: underline !important;
}

.ksm-btngroup.k-selected {
    border-color: #524F56;
    background-color: #524F56;
}