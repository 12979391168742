.k-i-add:before, .k-i-plus:before, .k-plus:before {
    content: "\E014";
    color: black;
}
.k-i-kpi-trend-equal:before, .k-i-minus:before, .k-minus:before {
    content: "\E015";
    color: black;
}

.software-updates-detail-grid table thead tr th:nth-of-type(1){
    width: 70%
}
.software-updates-detail-grid table tbody tr td:nth-of-type(1){
    width: 70%
}

.software-updates-detail-grid table thead tr th:nth-of-type(2){
    width: 30%
}
.software-updates-detail-grid table tbody tr td:nth-of-type(2){
    width: 30%
}

/*
Changes documentation grid header to default cursor
*/
.software-updates-doc-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.software-updates-doc-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.software-updates-doc-grid table thead tr th:nth-child(3) .k-cell-inner .k-link
.software-updates-doc-grid table thead tr th:nth-child(4) .k-cell-inner .k-link
.software-updates-doc-grid table thead tr th:nth-child(5) .k-cell-inner .k-link {
    cursor: default
}

/*
Set documentation grid column width
*/
.software-updates-doc-grid table thead tr th:nth-of-type(3){
    width: 25%
}

.software-updates-doc-grid table tbody tr td:nth-of-type(3){
    width: 25%
}

.software-updates-doc-grid table thead tr th:nth-of-type(4){
    width: 12%
}

.software-updates-doc-grid table tbody tr td:nth-of-type(4){
    width: 12%
}

.software-updates-doc-grid table thead tr th:nth-of-type(5){
    width: 7%
}
.software-updates-doc-grid table tbody tr td:nth-of-type(5){
    width: 7%
}

/*
Changes software firmware grid header to default cursor
*/
.software-updates-software-firmware-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.software-updates-software-firmware-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.software-updates-software-firmware-grid table thead tr th:nth-child(3) .k-cell-inner .k-link
.software-updates-software-firmware-grid table thead tr th:nth-child(4) .k-cell-inner .k-link
.software-updates-software-firmware-grid table thead tr th:nth-child(5) .k-cell-inner .k-link
.software-updates-software-firmware-grid table thead tr th:nth-child(6) .k-cell-inner .k-link {
    cursor: default
}

/*
Set software firmware grid column width
*/
.software-updates-software-firmware-grid table thead tr th:nth-of-type(3){
    width: 6%
}

.software-updates-software-firmware-grid table tbody tr td:nth-of-type(3){
    width: 12%
}

.software-updates-software-firmware-grid table thead tr th:nth-of-type(4){
    width: 25%
}

.software-updates-software-firmware-grid table tbody tr td:nth-of-type(4){
    width: 25%
}

.software-updates-software-firmware-grid table thead tr th:nth-of-type(5){
    width: 12%
}

.software-updates-software-firmware-grid table tbody tr td:nth-of-type(5){
    width: 12%
}

.software-updates-software-firmware-grid table thead tr th:nth-of-type(6){
    width: 7%
}
.software-updates-software-firmware-grid table tbody tr td:nth-of-type(6){
    width: 7%
}

/*
Changes external links grid header to default cursor
*/
.software-updates-external-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.software-updates-external-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.software-updates-external-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.software-updates-external-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.software-updates-external-grid table thead tr th:nth-child(5) .k-cell-inner .k-link {
    cursor: default
}

/*
Set external links grid column width
*/
.software-updates-external-grid table thead tr th:nth-of-type(3){
    width: 25%
}

.software-updates-external-grid table tbody tr td:nth-of-type(3){
    width: 25%
}

.software-updates-external-grid table thead tr th:nth-of-type(4){
    width: 12%
}

.software-updates-external-grid table tbody tr td:nth-of-type(4){
    width: 12%
}

.software-updates-external-grid table thead tr th:nth-of-type(5){
    width: 7%
}
.software-updates-external-grid table tbody tr td:nth-of-type(5){
    width: 7%
}

/*
Changes licenses grid header to default cursor
*/
.software-updates-licenses-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.software-updates-licenses-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.software-updates-licenses-grid table thead tr th:nth-child(3) .k-cell-inner .k-link {
    cursor: default
}

/*
Set licenses grid column width
*/
.software-updates-licenses-grid table thead tr th:nth-of-type(3){
    width: 44%
}

.software-updates-licenses-grid table tbody tr td:nth-of-type(3){
    width: 44%
}
