$keysight-red: #E90029;
$keysight-dark-red: #AF1326;
$keysight-pink: #FFC2C2;
$keysight-blue: #426DA9;
$keysight-light-purple: #F5E9F8;
$keysight-gray-6: #333333;
$keysight-gray-5: #403E44;
$keysight-gray-4: #524F56;
$keysight-gray-3: #D7D7D7;
$keysight-gray-2: #E3DFE8;
$keysight-gray-1: #F2F2F2;

$white: #FFFFFF;
$black: #000000;

// Kendo Imports
$linear-channel-values: (
        0
        .0003035269835488375
        .000607053967097675
        .0009105809506465125
        .00121410793419535
        .0015176349177441874
        .001821161901293025
        .0021246888848418626
        .0024282158683907
        .0027317428519395373
        .003035269835488375
        .003346535763899161
        .003676507324047436
        .004024717018496307
        .004391442037410293
        .004776953480693729
        .005181516702338386
        .005605391624202723
        .006048833022857054
        .006512090792594475
        .006995410187265387
        .007499032043226175
        .008023192985384994
        .008568125618069307
        .009134058702220787
        .00972121732023785
        .010329823029626936
        .010960094006488246
        .011612245179743885
        .012286488356915872
        .012983032342173012
        .013702083047289686
        .014443843596092545
        .01520851442291271
        .01599629336550963
        .016807375752887384
        .017641954488384078
        .018500220128379697
        .019382360956935723
        .0202885630566524
        .021219010376003555
        .022173884793387385
        .02315336617811041
        .024157632448504756
        .02518685962736163
        .026241221894849898
        .027320891639074894
        .028426039504420793
        .0295568344378088
        .030713443732993635
        .03189603307301153
        .033104766570885055
        .03433980680868217
        .03560131487502034
        .03688945040110004
        .0382043715953465
        .03954623527673284
        .04091519690685319
        .042311410620809675
        .043735029256973465
        .04518620438567554
        .046665086336880095
        .04817182422688942
        .04970656598412723
        .05126945837404324
        .052860647023180246
        .05448027644244237
        .05612849004960009
        .05780543019106723
        .0595112381629812
        .06124605423161761
        .06301001765316767
        .06480326669290577
        .06662593864377289
        .06847816984440017
        .07036009569659588
        .07227185068231748
        .07421356838014963
        .07618538148130785
        .07818742180518633
        .08021982031446832
        .0822827071298148
        .08437621154414882
        .08650046203654976
        .08865558628577294
        .09084171118340768
        .09305896284668745
        .0953074666309647
        .09758734714186246
        .09989872824711389
        .10224173308810132
        .10461648409110419
        .10702310297826761
        .10946171077829933
        .1119324278369056
        .11443537382697373
        .11697066775851084
        .11953842798834562
        .12213877222960187
        .12477181756095049
        .12743768043564743
        .1301364766903643
        .13286832155381798
        .13563332965520566
        .13843161503245183
        .14126329114027164
        .14412847085805777
        .14702726649759498
        .14995978981060856
        .15292615199615017
        .1559264637078274
        .1589608350608804
        .162029375639111
        .1651321945016676
        .16826940018969075
        .1714411007328226
        .17464740365558504
        .17788841598362912
        .18116424424986022
        .184474994500441
        .18782077230067787
        .19120168274079138
        .1946178304415758
        .19806931955994886
        .20155625379439707
        .20507873639031693
        .20863687014525575
        .21223075741405523
        .21586050011389926
        .2195261997292692
        .2232279573168085
        .22696587351009836
        .23074004852434915
        .23455058216100522
        .238397573812271
        .24228112246555486
        .24620132670783548
        .25015828472995344
        .25415209433082675
        .2581828529215958
        .26225065752969623
        .26635560480286247
        .2704977910130658
        .27467731206038465
        .2788942634768104
        .2831487404299921
        .2874408377269175
        .29177064981753587
        .2961382707983211
        .3005437944157765
        .3049873140698863
        .30946892281750854
        .31398871337571754
        .31854677812509186
        .32314320911295075
        .3277780980565422
        .33245153634617935
        .33716361504833037
        .3419144249086609
        .3467040563550296
        .35153259950043936
        .3564001441459435
        .3613067797835095
        .3662525955988395
        .3712376804741491
        .3762621229909065
        .38132601143253014
        .386429433787049
        .39157247774972326
        .39675523072562685
        .4019777798321958
        .4072402119017367
        .41254261348390375
        .4178850708481375
        .4232676699860717
        .4286904966139066
        .43415363617474895
        .4396571738409188
        .44520119451622786
        .45078578283822346
        .45641102318040466
        .4620769996544071
        .467783796112159
        .47353149614800955
        .4793201831008268
        .4851499400560704
        .4910208498478356
        .4969329950608704
        .5028864580325687
        .5088813208549338
        .5149176653765214
        .5209955732043543
        .5271151257058131
        .5332764040105052
        .5394794890121072
        .5457244613701866
        .5520114015120001
        .5583403896342679
        .5647115057049292
        .5711248294648731
        .5775804404296506
        .5840784178911641
        .5906188409193369
        .5972017883637634
        .6038273388553378
        .6104955708078648
        .6172065624196511
        .6239603916750761
        .6307571363461468
        .6375968739940326
        .6444796819705821
        .6514056374198242
        .6583748172794485
        .665387298282272
        .6724431569576875
        .6795424696330938
        .6866853124353135
        .6938717612919899
        .7011018919329731
        .7083757798916868
        .7156935005064807
        .7230551289219693
        .7304607400903537
        .7379104087727308
        .7454042095403874
        .7529422167760779
        .7605245046752924
        .768151147247507
        .7758222183174236
        .7835377915261935
        .7912979403326302
        .799102738014409
        .8069522576692516
        .8148465722161012
        .8227857543962835
        .8307698767746546
        .83879901174074
        .846873231509858
        .8549926081242338
        .8631572134541023
        .8713671191987972
        .8796223968878317
        .8879231178819663
        .8962693533742664
        .9046611743911496
        .9130986517934192
        .9215818562772946
        .9301108583754237
        .938685728457888
        .9473065367331999
        .9559733532492861
        .9646862478944651
        .9734452903984125
        .9822505503331171
        .9911020971138298
        1
);

$color-level-step: if(
  /* check for existance of bootstrap variable */
                variable-exists("theme-color-interval"),
                $theme-color-interval,
          /* fallback */
                8%
) !default;

@function color-level($color, $level: 0) {

  @if ($level == 0) {
    @return $color;
  }

  $base: if($level < 0, #ffffff, #000000);
  $level: abs($level);

  @if ( unit($level) == "%" ) {
    @return mix( $base, $color, $level );
  }

  @return mix($base, $color, $level * $color-level-step);
}

@function tint( $color, $level: 1 ) {
  @return color-level( $color, -1 * $level );
}

@function shade( $color, $level: 1 ) {
  @return color-level( $color, 1 * $level );
}

@function luminance($color) {
  $red: nth($linear-channel-values, red($color) + 1);
  $green: nth($linear-channel-values, green($color) + 1);
  $blue: nth($linear-channel-values, blue($color) + 1);

  @return .2126 * $red + .7152 * $green + .0722 * $blue;
}

$wcag-dark: black !default;
$wcag-light: white !default;

@function contrast-wcag($color, $dark: $wcag-dark, $light: $wcag-light) {
  $luma: luminance($color);
  $out: if($luma < .5, $light, $dark);
  // @debug $luma;
  @return $out;
}

$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$pager-item-selected-bg: $keysight-blue !default;
$pager-number-hover-opacity: .08 !default;

//---------------------- Begin KSM Changes ----------------------

// Theme Colors
$primary: $keysight-red !default;
$primary-lighter: tint( $keysight-red, 2 ) !default;
$primary-darker: shade( $keysight-red, 2 ) !default;
$primary-contrast: contrast-wcag( $keysight-red ) !default;

$secondary: $keysight-gray-5 !default;
$secondary-lighter: tint( $keysight-gray-5, 2 ) !default;
$secondary-darker: shade( $keysight-gray-5, 2 ) !default;
$secondary-contrast: contrast-wcag( $keysight-gray-5 ) !default;

// Headings
$h1-font-size: 2.25rem !default;
$h2-font-size: 1.875rem !default;
$h3-font-size: 1.125rem !default;
$h4-font-size: 1.25rem !default;
$h5-font-size: 0.75rem !default;

$h1-line-height: 2.688rem !default;
$h2-line-height: 1.5rem !default;
$h3-line-height: 1.375rem !default;
$h4-line-height: 1.5rem !default;
$h5-line-height: 0.875rem !default;

$h1-font-weight: $font-weight-normal !default;
$h2-font-weight: $font-weight-bold !default;
$h3-font-weight: $font-weight-bold !default;
$h4-font-weight: $font-weight-bold !default;
$h5-font-weight: $font-weight-bold !default;

$h2-margin: 0 0 1rem !default;
$h4-margin: 0 0 0.5rem !default;

// Link
$link-text: $black !default;

// Buttons
$kendo-button-font-size-lg: 1.125rem !default;
$kendo-button-padding-x-lg: 2rem !default;
$kendo-button-padding-y-lg: 0.75rem !default;

$kendo-button-disabled-bg: #9F9EA1 !default;
$kendo-button-disabled-border: #9F9EA1 !default;
$kendo-button-disabled-text: #FFFFFF !default;

// Grid
$grid-cell-horizontal-border-width: 0.063rem !default;
$grid-text: $keysight-gray-6 !default;
$grid-border: #AAAAAA !default;
$grid-header-bg: $keysight-gray-4 !default;
$grid-header-text: $white !default;
$grid-selected-bg: $keysight-light-purple !default;
$grid-sorted-bg: $keysight-blue !default;
$grid-sorting-indicator-text: $white !default;
$grid-font-size: 1rem !default;

$kendo-list-item-hover-bg: $keysight-blue !default;
$kendo-list-item-hover-text: $white !default;
$kendo-list-item-selected-bg: $keysight-blue !default;
$kendo-list-item-selected-text: $white !default;

// Custom CSS within kendo-default-theme all.scss
// These are manual changes that were made within their all.scss

//tbody tr:not(.k-detail-row):hover,
//tbody tr:not(.k-detail-row).k-state-hover {
//    color: $grid-hovered-text;
//    background-color: $grid-hovered-bg;
//}

// Grid - Pager
$pager-number-text: $keysight-blue !default;
$pager-item-hover-bg: rgba($pager-item-selected-bg, $pager-number-hover-opacity) !default;
$pager-item-hover-text: $keysight-blue !default;
$pager-item-text: $keysight-blue !default;

// Custom CSS within kendo-default-theme all.scss
// These are manual changes that were made within their all.scss

//col.k-sorted {
//        background-color: $white;
//}
//
//.k-grid-header .k-hierarchy-cel .k-icon:hover {
//        background-color: transparent;
//}

// Used to remove the number of sort order because user are unaware of host id type sort order
//.k-sort-order {
//            display: none;
//            //display: inline-block;
//            vertical-align: top;
//            height: $icon-size;
//            font-size: $grid-sorting-index-font-size;
//            margin-top: $grid-sorting-index-spacing-y;
//            margin-left: $grid-sorting-index-spacing-x;
//        }

// Checkbox
$kendo-checkbox-border: $keysight-blue !default;
$kendo-checkbox-checked-bg: $keysight-blue !default;
$kendo-checkbox-checked-text: contrast-wcag( $kendo-checkbox-checked-bg ) !default;
$kendo-checkbox-checked-border: $kendo-checkbox-checked-bg !default;
$kendo-checkbox-focus-shadow: 0 !default;
$kendo-checkbox-focus-checked-shadow: 0 !default;
//$kendo-checkbox-border-width: 2px !default;

// added border radius
//.k-checkbox {
//        border-radius: 0;
//}

// Stepper
$stepper-indicator-done-bg: $keysight-blue  !default;
$stepper-progressbar-fill-bg: $keysight-blue  !default;

// Custom CSS within kendo-default-theme all.scss
// These are manual changes that were made within their all.scss

//.k-step-label .k-step-text {
//            //max-width: calc(10em - (#{$icon-size} + #{$icon-spacing}));
//            flex-grow: 1;
//            white-space: nowrap;
//            text-overflow: ellipsis;
//            overflow: hidden;
//        }

//.k-step-link {
//            margin: auto;
//            max-width: 13em;
//            flex-direction: column;
//        }

//Commented out for bug
//&::after {
//  @include border-radius(100%);
//  content: "";
//  border-width: $stepper-indicator-focus-border-width;
//  border-style: solid;
//  border-color: inherit;
//  pointer-events: none;
//  display: none;
//  position: absolute;
//  top: calc(-1 * #{$stepper-indicator-focus-calc-offset});
//  right: calc(-1 * #{$stepper-indicator-focus-calc-offset});
//  bottom: calc(-1 * #{$stepper-indicator-focus-calc-offset});
//  left: calc(-1 * #{$stepper-indicator-focus-calc-offset});
//  z-index: 2;
//}

//PanelBar
$panelbar-text: #FFFFFF !default;
$panelbar-header-bg: $keysight-blue !default;
$panelbar-header-text: #FFFFFF !default;
$panelbar-header-selected-bg: $keysight-blue !default;
$panelbar-border-width: 0 !default;

// Custom CSS within kendo-default-theme all.scss
// These are manual changes that were made within their all.scss

//.k-panelbar-toggle {
//    margin-inline-start: revert;
//    // margin-inline-start: auto;
//}

//Added to their scss
//.k-panelbar-item-text {
//        width:100%;
//        order:1;
//    }

//> .k-panelbar-header {
//    border-width: 0;
//    border-style: solid;
//    border-color: inherit;
//    display: block;
//    margin-bottom: 0.938rem;

//Toggle Switch
$kendo-switch-on-track-bg: $keysight-blue !default;

// Disabled state
$disabled-opacity: .7 !default; // increase disabled default opacity

@import "../kendo-theme-default/all.scss";

// To compile this file
// Move into kendo-custom-theme directory and run:
// sass kendo-custom-theme.scss kendo-custom-theme.css