.sync-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.sync-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.sync-grid table thead tr th:nth-child(3) .k-cell-inner .k-link
.sync-grid table thead tr th:nth-child(4) .k-cell-inner .k-link {
    cursor: default;
}

.sync-grid table thead tr th:nth-of-type(1){
    width: 10%
}
.sync-grid table tbody tr td:nth-of-type(1){
    width: 10%
}

.sync-grid table thead tr th:nth-of-type(3){
    width: 12%
}
.sync-grid table tbody tr td:nth-of-type(3){
    width: 12%
}

.sync-grid table thead tr th:nth-of-type(4){
    width: 12%
}
.sync-grid table tbody tr td:nth-of-type(4){
    width: 12%
}