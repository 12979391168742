.header-contact-us {
    color: #524f56;
    text-decoration: none;
    min-width: 4rem;
}

.header-contact-us:hover {
    border-bottom: 0.063rem solid #524f56;
}

.header-button {
    border: none;
    padding: 0.625rem;
    margin-left: 0.75rem;
    background-repeat: no-repeat !important;
}

.header-button:hover {
    background-color: white;
    cursor: pointer;
}

.header-button:focus {
    box-shadow: none;
    background-color: #f8f8f8;
}

.header-popup {
    border-color: #f8f8f8;
    border-bottom: 0.125rem solid #d8d8d8;
}

.header-button-menu {
    background: #f8f8f8;
    padding: 1.75rem 0.75rem 0.75rem;
}

.header-button-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.header-button-menu li {
    padding: 0.313rem 0.75rem;
    font-size: 1rem;
}

.header-button-menu a {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--keysight-primary);
    text-decoration: none;
}

.header-button-menu li a:hover {
    border-bottom: 0.063rem solid var(--keysight-primary);
}

.header-button-menu h4 {
    font-size: 1.2rem;
    color: rgb(32,32,32);
    font-weight: 700;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin: 0;
    padding-bottom: 0.313rem;
}

.language-button {
    font-family: Neue Helvetica Condensed;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--keysight-primary);
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
}

.language-button:hover {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
}

.header-separator {
    color: #dbd9dd;
    margin-left: 0.75rem;
}

.close-x {
    padding: 0;
    background: #f8f8f8;
    border: none;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    width: 0 !important;
    height: 0 !important;
}

.close-x:hover {
    background: #f8f8f8;
}

.close-x:focus {
    box-shadow: none;
}

.k-i-x {
    font-size: 1.375rem;
    color: var(--keysight-primary) !important;
}

.header-button-menu-welcome {
    padding: 1.75rem 0.75rem 0.75rem;
}

.header-button-menu-login {
    padding: 1.75rem 0.75rem 0.75rem;
}

.header-button-menu-globe {
    padding: 1.75rem 0.75rem 0.75rem;
    min-width: 19.375rem;
}

.locale-default {
    font-size: 1.125rem;
    border-bottom: 0.125rem solid #dbd9dd;
    color: #a4a1a9;
    padding: 0.313rem 0.75rem;
    margin-top: 0;
}

.k-page-header {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
    margin-top: 1rem;
}