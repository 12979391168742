.enter-transport-grid table thead tr th:nth-of-type(1){
    width: 1%
}

.enter-transport-grid table tbody tr td:nth-of-type(1){
    width: 1%
}

.enter-transport-grid table thead tr th:nth-of-type(2){
    width: 8%
}

.enter-transport-grid table tbody tr td:nth-of-type(2){
    width: 8%
}

.enter-transport-grid table thead tr th:nth-of-type(3){
    width: 10%
}

.enter-transport-grid table tbody tr td:nth-of-type(3){
    width: 10%
}

.enter-transport-grid table thead tr th:nth-of-type(4){
    width: 15%
}

.enter-transport-grid table tbody tr td:nth-of-type(4){
    width: 15%
}

.enter-transport-grid table thead tr th:nth-of-type(5){
    width: 13%
}

.enter-transport-grid table tbody tr td:nth-of-type(5){
    width: 13%
}

.enter-transport-grid table thead tr th:nth-of-type(7){
    width: 5%
}

.enter-transport-grid table tbody tr td:nth-of-type(7){
    width: 5%
}

.enter-transport-grid table thead tr th:nth-of-type(8){
    width: 8%
}

.enter-transport-grid table tbody tr td:nth-of-type(8){
    width: 8%
}

.unassigned-transport-grid table tbody tr.k-state-disabled {
    pointer-events: auto;
}

.unassigned-transport-grid table thead tr th:nth-of-type(1){
    width: 1%
}

.unassigned-transport-grid table tbody tr td:nth-of-type(1){
    width: 1%
}

.unassigned-transport-grid table thead tr th:nth-of-type(2){
    width: 15%
}

.unassigned-transport-grid table tbody tr td:nth-of-type(2){
    width: 15%
}

.unassigned-transport-grid table thead tr th:nth-of-type(4){
    width: 8%
}

.unassigned-transport-grid table tbody tr td:nth-of-type(4){
    width: 8%
}

.review-banked-gird table thead tr th:nth-child(1) .k-cell-inner .k-link,
.review-banked-gird table thead tr th:nth-child(2) .k-cell-inner .k-link,
.review-banked-gird table thead tr th:nth-child(3) .k-cell-inner .k-link,
.review-banked-gird table thead tr th:nth-child(4) .k-cell-inner .k-link,
.review-banked-gird table thead tr th:nth-child(5) .k-cell-inner .k-link {
    cursor: default
}


.review-banked-gird table thead tr th:nth-of-type(1){
    width: 8%
}

.review-banked-gird table tbody tr td:nth-of-type(1){
    width: 8%
}

.review-banked-gird table thead tr th:nth-of-type(2){
    width: 11%
}

.review-banked-gird table tbody tr td:nth-of-type(2){
    width: 11%
}

.review-banked-gird table thead tr th:nth-of-type(3){
    width: 13%
}

.review-banked-gird table tbody tr td:nth-of-type(3){
    width: 13%
}

.review-banked-gird table thead tr th:nth-of-type(5){
    width: 5%
}

.review-banked-gird table tbody tr td:nth-of-type(5){
    width: 5%
}

.assign-host-delete-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.assign-host-delete-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.assign-host-delete-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.assign-host-delete-grid table thead tr th:nth-child(4) .k-cell-inner .k-link {
    cursor: default
}


.assign-host-delete-grid table thead tr th:nth-of-type(1){
    width: 1%
}

.assign-host-delete-grid table tbody tr td:nth-of-type(1){
    width: 1%
}

.assign-host-delete-grid table thead tr th:nth-of-type(2){
    width: 13%
}

.assign-host-delete-grid table tbody tr td:nth-of-type(2){
    width: 13%
}

.assign-host-delete-grid table thead tr th:nth-of-type(4){
    width: 8%
}

.assign-host-delete-grid table tbody tr td:nth-of-type(4){
    width: 8%
}

.assign-host-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.assign-host-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.assign-host-grid table thead tr th:nth-child(3) .k-cell-inner .k-link {
    cursor: default
}

.assign-host-grid table thead tr th:nth-of-type(1){
    width: 13%
}

.assign-host-grid table tbody tr td:nth-of-type(1){
    width: 13%
}

.assign-host-grid table thead tr th:nth-of-type(3){
    width: 8%
}

.assign-host-grid table tbody tr td:nth-of-type(3){
    width: 8%
}



