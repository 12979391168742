#footer {
    border-top: 0.063em solid #a4a1a9;
    background-color: #edecee;
    padding-top: 1em;
    padding-bottom: 1.5em;
    flex-shrink: 0;
}

.wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 77.5rem;
    padding: 0 1.25rem;
}

.wrapper > div, .footer-columns > div {
    position: relative;
}

@media (min-width: 300px) {
    .footer-columns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 480px) {
    .footer-columns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

.footer-columns {
    padding-top: 1em;
    margin-bottom: 0.5em;
}

@media (min-width: 480px) {
    .footer-column {
        margin: 0 2em;
        width: calc(50% - 4em);
    }
}

@media (min-width: 645px) {
    .footer-column {
        width: calc(33% - 4em);
    }
}

@media (min-width: 860px) {
    .footer-column {
        width: calc(25% - 4em);
    }
}

.footer-column ul, .footer-column li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-column ul {
    margin-bottom: 1.5em;
}

.footer-column li {
    font-size: 1em;
    margin-bottom: 0.35em;
    list-style: none;
}

#footer a {
    color: #262626;
    text-decoration: none;
}

#footer a:hover {
    text-decoration: underline;
}

.footer-column h4 {
    font-size: 1em;
    color: #524f56;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

#footer-social {
    margin-left: -0.5em;
}

@media (min-width: 300px) {
    #copyright {
        margin: 0 1em;
    }
}

@media (min-width: 645px) {
    #copyright {
        margin: 0 2em;
    }
}

#copyright ul {
    margin: 0;
    padding: 0.75em 0 0 0;
}

#copyright li {
    display: inline-block;
    vertical-align: top;
    color: #a4a1a9;
}

#copyright li + li::before {
    content: "|";
    color: #c8c6cb;
    margin: 0 0.5em;
}
