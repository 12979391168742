/* Set column widths for my products table */
.license-grid  table thead tr th:nth-of-type(2){
    width: 10%
}
.license-grid table tbody tr td:nth-of-type(2){
    width: 10%
}

.license-grid  table thead tr th:nth-of-type(4){
    width: 5%
}
.license-grid table tbody tr td:nth-of-type(4){
    width: 5%
}

.license-grid  table thead tr th:nth-of-type(5){
    width: 7%
}
.license-grid table tbody tr td:nth-of-type(5){
    width: 7%
}

.license-grid  table thead tr th:nth-of-type(6){
    width: 12%
}
.license-grid table tbody tr td:nth-of-type(6){
    width: 12%
}

.license-grid  table thead tr th:nth-of-type(7){
    width: 12%
}
.license-grid table tbody tr td:nth-of-type(7){
    width: 12%
}

.license-grid a {
    margin-bottom: 0;
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.license-grid table thead tr th:nth-child(5) .k-cell-inner .k-link {
    cursor: default;
}

.license-grid table tbody tr.k-state-disabled {
    pointer-events: auto;
}

/* Set column widths for table inside host modal*/
.assign-products-grid table thead tr th:nth-of-type(1){
    width: 20%
}
.assign-products-grid table tbody tr td:nth-of-type(1){
    width: 20%
}

.assign-products-grid table thead tr th:nth-of-type(2){
    width: 70%
}
.assign-products-grid table tbody tr td:nth-of-type(2){
    width: 70%
}

.assign-products-grid table thead tr th:nth-of-type(3){
    width: 10%
}
.assign-products-grid table tbody tr td:nth-of-type(3){
    width: 10%
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.assign-products-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.assign-products-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.assign-products-grid table thead tr th:nth-child(3) .k-cell-inner .k-link {
    cursor: default;
}

/* Set column widths for assigned host accordion tables in assign host step*/
.assign-products-panelbar-grid  table thead tr th:nth-of-type(2){
    width: 10%
}
.assign-products-panelbar-grid table tbody tr td:nth-of-type(2){
    width: 10%
}

.assign-products-panelbar-grid table thead tr th:nth-of-type(4){
    width: 7%
}
.assign-products-panelbar-grid table tbody tr td:nth-of-type(4){
    width: 7%
}

.assign-products-panelbar-grid table thead tr th:nth-of-type(5){
    width: 15%
}
.assign-products-panelbar-grid table tbody tr td:nth-of-type(5){
    width: 15%
}

.assign-products-panelbar-grid table thead tr th:nth-of-type(6){
    width: 15%
}
.assign-products-panelbar-grid table tbody tr td:nth-of-type(6){
    width: 15%
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.assign-products-panelbar-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.assign-products-panelbar-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.assign-products-panelbar-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.assign-products-panelbar-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.assign-products-panelbar-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.assign-products-panelbar-grid table thead tr th:nth-child(6) .k-cell-inner .k-link {
    cursor: default;
}


/* Set column widths for assigned host accordion tables in license preview and download step*/
.license-preview-download-grid table thead tr th:nth-of-type(1){
    width: 11.5%
}
.license-preview-download-grid table tbody tr td:nth-of-type(1){
    width: 11.5%
}

.license-preview-download-grid table thead tr th:nth-of-type(3){
    width: 4%
}
.license-preview-download-grid table tbody tr td:nth-of-type(3){
    width: 4%
}

.license-preview-download-grid table thead tr th:nth-of-type(4){
    width: 15%
}
.license-preview-download-grid table tbody tr td:nth-of-type(4){
    width: 15%
}

.license-preview-download-grid table thead tr th:nth-of-type(5){
    width: 15%
}
.license-preview-download-grid table tbody tr td:nth-of-type(5){
    width: 15%
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.license-preview-download-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.license-preview-download-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.license-preview-download-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.license-preview-download-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.license-preview-download-grid table thead tr th:nth-child(5) .k-cell-inner .k-link {
    cursor: default;
}

textarea {
    resize: none;
}

.assign-products-modal .k-dialog-titlebar {
    background-color: transparent;
}

.assign-products-modal .k-i-x {
    color: #424242 !important;
}