.host-config-grid .k-grid-header col:nth-of-type(1){
    width: 2%
}
.host-config-grid .k-grid-table col:nth-of-type(1){
    width: 2%
}

.host-config-grid .k-grid-header col:nth-of-type(2){
    width: 12%
}
.host-config-grid .k-grid-table col:nth-of-type(2){
    width: 12%
}

.host-config-grid .k-grid-header col:nth-of-type(3){
    width: 6%
}
.host-config-grid .k-grid-table col:nth-of-type(3){
    width: 6%
}

.host-config-grid .k-grid-header col:nth-of-type(4){
    width: 17%
}
.host-config-grid .k-grid-table col:nth-of-type(4){
    width: 17%
}

.host-config-grid .k-grid-header col:nth-of-type(5){
    width: 8%
}
.host-config-grid .k-grid-table col:nth-of-type(5){
    width: 8%
}

.host-config-grid .k-grid-header col:nth-of-type(6){
    width: 5%
}
.host-config-grid .k-grid-table col:nth-of-type(6){
    width: 5%
}

.host-config-grid .k-grid-header col:nth-of-type(7){
    width: 6%
}
.host-config-grid .k-grid-table col:nth-of-type(7){
    width: 6%
}

.host-config-grid .k-grid-header col:nth-of-type(8){
    width: 44%
}
.host-config-grid .k-grid-table col:nth-of-type(8){
    width: 44%
}

.show-licenses-grid table thead tr th:nth-of-type(1) {
    width: 12%
}
.show-licenses-grid table tbody tr td:nth-of-type(1) {
    width: 12%
}

.show-licenses-grid table thead tr th:nth-of-type(3) {
    width: 8%
}
.show-licenses-grid table tbody tr td:nth-of-type(3) {
    width: 8%
}

.show-licenses-grid table thead tr th:nth-of-type(4) {
    width: 12%
}
.show-licenses-grid table tbody tr td:nth-of-type(4) {
    width: 12%
}

.show-licenses-grid table thead tr th:nth-of-type(5) {
    width: 4%
}
.show-licenses-grid table tbody tr td:nth-of-type(5) {
    width: 4%
}

.show-licenses-grid table thead tr th:nth-of-type(6) {
    width: 12%
}
.show-licenses-grid table tbody tr td:nth-of-type(6) {
    width: 12%
}

.show-licenses-grid table thead tr th:nth-of-type(7) {
    width: 12%
}
.show-licenses-grid table tbody tr td:nth-of-type(7) {
    width: 12%
}

.show-licenses-modal.ksm-dialog > .k-dialog > .k-dialog-buttongroup {
    justify-content: start;
    padding: 1.313rem;
    gap: 0.938rem;
}

.flex {
    display: flex;
    flex-direction: column;
}

.flex-grow-1 {
    flex-grow: 1;
}