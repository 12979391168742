.k-form .k-form-field {
    margin-top: .9rem;
}

.add-cert-field {
    width: 18.75rem;
}

.add-cert-error {
    width: 43.75rem;
}

.k-i-close {
    font-size: 1rem;
    color: var(--keysight-secondary) !important;
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.add-cert-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.add-cert-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.add-cert-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.add-cert-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.add-cert-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.add-cert-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.add-cert-grid table thead tr th:nth-child(7) .k-cell-inner .k-link {
    cursor: default;
}